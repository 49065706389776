import { render, staticRenderFns } from "./ArrowScrollContainer.vue?vue&type=template&id=255169c6&scoped=true"
import script from "./ArrowScrollContainer.vue?vue&type=script&lang=js"
export * from "./ArrowScrollContainer.vue?vue&type=script&lang=js"
import style0 from "./ArrowScrollContainer.vue?vue&type=style&index=0&id=255169c6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "255169c6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconCarouselArrowLeft: require('/opt/build/repo/components/icons/IconCarouselArrowLeft.vue').default,IconCarouselArrowRight: require('/opt/build/repo/components/icons/IconCarouselArrowRight.vue').default})
