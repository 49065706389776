
import { mapState, mapActions, mapMutations } from 'vuex';


export default {
  props: {
    blok: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      currentCatIndex: 0,
      menuCats: null,
      title: ''
    }
  },
  async mounted() {
    if (this.location) {
      this.title = this.blok.logged_in_title
      this.currentCatIndex = 0
      await this.loadMenu()
    } else {
      this.title = this.blok.title
      this.currentCatIndex = 0
      await this.loadDefaultMenu()
    }
  },
  methods: {
    ...mapActions(['sendRequest', 'separateMenus']),
    ...mapMutations(['setLoading', 'setErrors', 'setMenu']),

    async loadDefaultMenu() {
      try {
        const config = {
          method: 'get',
          url: `menus/${this.blok?.default_menu_id}`
        }
        const response = await this.sendRequest(config)

        if (response.status === 'ok') {
          this.menuCats = response.data
          this.separateMenus(response.data)
        } else {
          // TODO: I disabled this for now because loading default menu does not work for catering
          // this.setErrors(response.message)
        }

      } catch (error) {
        console.log(error)
      }
    },
    async loadMenu() {
      if (!this.menu) {
        try {
          const config = {
            method: 'get',
            url: `menus/${this.blok?.default_menu_id}`
          }
          const response = await this.sendRequest(config)

          if (response.status === 'ok') {
            this.menuCats = response.data
            this.separateMenus(response.data)
          } else {
            this.setErrors(response.message)
          }

        } catch (error) {
          console.log(error)
        }
      } else {
        this.menuCats = await this.menu
      }
    },
    selectCat(p) {
      this.currentCatIndex = p.i
    }
  },
  computed: {
    ...mapState([
      'menu',
      'location'
    ]),
    category() {
      if (!this.menuCats) return null

      if (this.location) {
        return this.featuredProductsCategory
      }

      return this.menuCats.categories[this.currentCatIndex]
    },
    featuredProductsCategory() {
      const featuredProducts = this.menuCats.categories.reduce((acc, cat) => {
        const featured = cat.products.filter(product => product?.featured_home)
        return [...acc, ...featured]
      }, [])

      const category = {
        name: 'Featured Items',
        products: featuredProducts
      }

      return category
    },
    isFastFine() {
      return this.location?.c_fast_fine;
    }
  }
}
