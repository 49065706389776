import { render, staticRenderFns } from "./ProductCard.vue?vue&type=template&id=77059bef&scoped=true"
import script from "./ProductCard.vue?vue&type=script&lang=js"
export * from "./ProductCard.vue?vue&type=script&lang=js"
import style0 from "./ProductCard.vue?vue&type=style&index=0&id=77059bef&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77059bef",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconGlutenFree: require('/opt/build/repo/components/icons/IconGlutenFree.vue').default,IconVegetarian: require('/opt/build/repo/components/icons/IconVegetarian.vue').default,IconVegan: require('/opt/build/repo/components/icons/IconVegan.vue').default,IconArrowRight: require('/opt/build/repo/components/icons/IconArrowRight.vue').default,IconCheckFilled: require('/opt/build/repo/components/icons/IconCheckFilled.vue').default,IconPlusCircle: require('/opt/build/repo/components/icons/IconPlusCircle.vue').default})
