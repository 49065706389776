
import { mapState, mapMutations } from 'vuex'
/*   default test id  27719 */
export default {
  data() {
    return {
      showModal: false,
      selectedCat: 0,
    }
  },
  props: {
    category: {
      type: Object,
      default: () => { }
    },
    white: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState(['location']),
  },
  methods: {
    ...mapMutations({
      setProduct: 'product/setProduct',
    }),
    hideQuickAdd() {
      this.setProduct(null)
      document.body.style.overflow = 'auto'
    },
    hideQuickAddOpenCart() {
      this.setProduct(null)
      document.body.style.overflow = 'hidden'
    },
  },
}
