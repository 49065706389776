
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      scrollPosition: 'front',
      selectedCategory: 0,
      scrolling: false,
      showFixed: false
    }
  },
  props: {
    categories: {
      type: Array,
      default: []
    }
  },
  computed: {
    ...mapState(['cart']),
    mobile() {
      return this.$mq == 'sm' || this.$mq == 'md'
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.scroll)
  },
  methods: {
    ...mapActions(['filterMenu']),
    setSelectedCategory(category, i) {
      this.selectedCategory = i
      this.$emit('catAfterSelect', {
        category,
        i
      })
    },
    getScrollPosition() {
      if (this.scrolling) {
        return false
      }
      const ul = this.$refs.menuList
      let position = 'middle'
      if (ul.scrollLeft == 0) {
        position = 'front'
      } else if (ul.scrollWidth - ul.offsetWidth == Math.round(ul.scrollLeft)) {
        position = 'end'
      }
      this.scrollPosition = position

    },
    scrollLeft() {
      this.scrolling = true
      setTimeout(() => {
        this.scrolling = false
        this.getScrollPosition()
      }, 500)
      const ul = this.$refs.menuList
      ul.scrollTo({
        left: ul.scrollLeft - 200,
        behavior: 'smooth'
      })
    },
    scrollRight() {
      this.scrolling = true
      setTimeout(() => {
        this.scrolling = false
        this.getScrollPosition()
      }, 500)
      const ul = this.$refs.menuList
      ul.scrollTo({
        left: ul.scrollLeft + 200,
        behavior: 'smooth'
      })
    },
  }
}
