
export default {
  props: {
    arrowsBackground: {
      type: String,
      default: "#BAF54D",
    },
    arrowsColor: {
      type: String,
      default: "#3D098E",
    },
    showArrowsMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isScrollableLeft: false,
      isScrollableRight: false,
    };
  },
  computed: {
    isMobile() {
      return this.$mq === "sm" || this.$mq === "md" ? true : false;
    },
  },
  async mounted() {
    if (!this.isMobile || this.showArrowsMobile) {
      window.addEventListener("resize", () => this.checkForOverflow());

      await this.$nextTick();
      this.checkForOverflow();
    }
  },
  updated() {
    if (!this.isMobile) {
      this.checkForOverflow();
    }
  },
  methods: {
    checkForOverflow() {
      const carousel = this.$refs.carousel;
      if (!carousel) return;

      this.isScrollableLeft = carousel.scrollLeft > 0;
      this.isScrollableRight =
        carousel.scrollLeft < carousel.scrollWidth - carousel.clientWidth;
    },
    scrollLeft() {
      const carousel = this.$refs.carousel;
      carousel.scrollLeft -= this.isMobile ? 350 : 700;
    },
    scrollRight() {
      const carousel = this.$refs.carousel;
      carousel.scrollLeft += this.isMobile ? 350 : 700;
    },
  },
};
