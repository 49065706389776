import { render, staticRenderFns } from "./FeaturedMenuCategory.vue?vue&type=template&id=9f043e22&scoped=true"
import script from "./FeaturedMenuCategory.vue?vue&type=script&lang=js"
export * from "./FeaturedMenuCategory.vue?vue&type=script&lang=js"
import style0 from "./FeaturedMenuCategory.vue?vue&type=style&index=0&id=9f043e22&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f043e22",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductCard: require('/opt/build/repo/components/product/ProductCard.vue').default,ArrowScrollContainer: require('/opt/build/repo/components/common/ArrowScrollContainer.vue').default,QuickAddModal: require('/opt/build/repo/components/product/QuickAddModal.vue').default})
